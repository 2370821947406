.main{
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
}

.col{
    float: left;
    padding-right: 20px;
}

.ro{
    display: flex;
    clear: both;
}

.adinf{
    font-size: 15px;
}

.stand{
    text-align: right;
}

.head{
    padding-bottom: 0;
    margin-bottom: 0;
    
}

.sub{
    padding-top: 0;
    margin-top: 0;
}
