.App {
  text-align: center;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap{
  flex:1;
  
}

.content{
  position: relative;
  padding-top: 100px;
  height: 100%;
}

.spacer{
  position: relative;
  aspect-ratio: 900/50;
  height: 20%;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}


.layer1{
  background-image: url('./assets/TransitionGreenBottom.svg');
}

.layer2{
  background-image: url('./assets/TransitionPinkBottom.svg');
}

.layer3{
  background-image: url('./assets/TransitionGreenTop.svg');

}

.layer4{
  background-image: url('./assets/TransitionPinkTop.svg');
}
