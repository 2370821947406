.footer{
    color: white;
    background-color: #31a73f;
    width: 100%;
    position: relative;
    bottom: 0;
    text-align: left;
   
}
.row{
    clear: both;
}

.container{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.column{
    float: left;
    padding: 0 40px 0 40px;
    
}

.column a{
    color: white;
}

.column ul{
    list-style: none;
    padding: 0;
}

.bot{
    padding: 1px 10px 1px 10px;
    background-color: #21722b;
}