@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&family=Gwendolyn:wght@400;700&family=MonteCarlo&family=Parisienne&display=swap');

:root {
    --lightgreen: #ffffff;
    --green: #b5e61d;
    --purple: #89ec90;
    --lightpurple: #ffffff;
    --bright: #c0c0f573;
    
}

.display-linebreak {
    white-space: pre-line;
    font-weight: normal;
    margin: auto;
}

.welcomemessage {
    position: relative;
    top: 80vh;
    display: flex;
    justify-content: center;
    font-family: 'Mr Dafoe', cursive;
    background-color: #30a63ebf;
    color: white;
    width: auto;
    height: 20vh;
    font-size: x-large;
}

.welcomemessagebackground{
    display: block;
    text-align: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background-image: url("../assets/start_background.jpg");
    background-size: 120vw;
    background-position: center;
    background-repeat: no-repeat;
    width: auto;
    margin-top: -12vh;

}

.homesections{
    margin: auto;
    width: 85vw;
    font-size: larger;
}


h2{
    font-size: xx-large;
    text-align: left;
}

.trenn{
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.aboutme{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5vh;
}

.paragraph{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
}

.textr{
    padding-left: 20px;
    text-align: justify;
}

.textl{
    padding-right: 20px;
    text-align: justify;
}

.picturel img {
    height: 40vh;
}

.picturer img {
    height: 40vh;
}

.picturer{
    flex-basis: 70%;
    order: 2;
}

.picturel{
    flex-basis: 30%;
   
}


@media screen and (max-width: 1200px) {
    .welcomemessage {
        position: relative;
        top: 40vh;
        display: flex;
        justify-content: center;
        font-family: 'Mr Dafoe', cursive;
        background-color: #30a63ebf;
        color: white;
        width: 100vw;
        height: 10vh;
        font-size: large;
    }
    
    .welcomemessagebackground{
        display: block;
        text-align: center;
        align-items: center;
        position: relative;
        height: 50vh;
        background-image: url("../assets/start_background.jpg");
        background-size: 120vw;
        background-position: center;
        background-repeat: no-repeat;
        width: 100vw;
        margin-top: -10vh;
    
    }



    .homesections{
        margin: auto;
        width: 90vw;
        font-size: larger;
    }
    
    
    .aboutme{
        display: block;
        align-items: center;
        justify-content: center;
        padding-bottom: 5vh;
    }
    
    .paragraph{
        display: block;
        align-items: center;
        justify-content: center;
        padding-bottom: 10vh;
    }
    
    .textr{
        padding: 0;
        text-align: justify;
    }
    
    .textl{
        padding: 0;
        text-align: justify;
    }
    
    .picturel img {
        object-fit: cover;
        width: 100%;
    }
    
    .picturer img {
        object-fit: cover;
        width: 100%;
    }
    
    .picturer{
        flex-basis: 70%;
        order: 2;
    }
    
    .picturel{
        flex-basis: 30%;
       
    }
}

@media screen and (max-width: 1200px) {
    .welcomemessage {
        position: relative;
        top: 30vh;
        display: flex;
        justify-content: center;
        font-family: 'Mr Dafoe', cursive;
        background-color: #30a63ebf;
        color: white;
        width: 100vw;
        height: 10vh;
        font-size: large;
    }
    
    .welcomemessagebackground{
        display: block;
        text-align: center;
        align-items: center;
        position: relative;
        height: 40vh;
        background-image: url("../assets/start_background.jpg");
        background-size: 120vw;
        background-position: center;
        background-repeat: no-repeat;
        width: 100vw;
        margin-top: -10vh;
    
    }
   
}


@media screen and (max-height: 1000px) and (max-width: 1200px){
    .welcomemessage {

        font-size: small;
    }
}





