.main{
    font-family: 'Montserrat', sans-serif;
    font-size: medium;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
}

.stand{
    text-align: right;
}

.head{
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: x-large;
}

.sub{
    padding-top: 0;
    margin-top: 0;
    font-size: large;
    text-align: left;
}