
.nav{
    background: #ffffff;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    z-index: 5;
    position: fixed; 
    top: 0; 
    width: 100vw;
    box-shadow: 0px 1px 5px silver;
}


.logowrap{
    display: block;
    object-fit: cover;
    max-height: 80px;
    max-width: 80px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 10%;
    cursor: pointer;
    color: #fff;
    font-size: 2rem;
    text-decoration: none;
}

.logo{
    width: 100%;
}

.navtext{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow Bold', 'Arial', 'sans-serif';
    align-items: center;
    display: flex;
    justify-content: center;
}

.links{
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 24px;
    list-style: none;
}


.dropdown{
    position: absolute;
    background-color:#31a73f;
    padding: 0px 10px 0px 10px;
    margin: 0px;
    top: 110%;
    left: 50%;
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}

.dropdown ul{
    padding: 0;
}

.dropdown li{
    margin: 10px 0px 10px 0px;
    list-style: none;
    text-align: center;
}

.navlink{
    font-family:  Arial;
    font-weight: bold;
    color: #46e059;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    position: relative;
}

.navlink.active {
    color:#000000;
}
.navlink:hover {
    color: #000000;
}
.navlinkd {
    font-family:  Arial;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
}

.navlinkd.active {
    color:#000000;
}
.navlinkd:hover {
    color: #000000;
}

.bars{
    display: none;
    color: rgb(0, 0, 0);
}

.navheader{
    font-size: 22px;
}



@media screen and (max-width: 768px) {
    .navmenu {
        display: block;
        margin: 0;
        padding: 0;
        right: -320px;
        background: #31a73f;
        border-top: 5px solid #46e059;
        height: 100vh;
        width:  300px;
        position: absolute;
        top: calc(80px + 10px);
        transition: 1s;
        
    }

    .navmenu.active{
        width: 320px;
        right: 0;
        transform: 1s;
    }

    .links{
        display: block;
        position: absolute;
        left: 10px;
        margin: 0;
        padding: 0;
        text-align: left;
        font-size:  20px;

        
    }

    .dropdown ul{
        padding: 0;
        margin: 0;
    }

    .dropdown{
        display: block;
        position: relative;
        top: 0px;
        right: 0px;
        text-align: left;
    }


    .dropdown li{
        margin: 20px 0px 0px 0px;
        text-align: left;
    }

    .navlink{
        color: white;
        text-align: left;
        width: 300px;
        margin-top: 5%;
        padding-top: 2px;
    }

    .bars.div{
        display: flex;
        width:  25vw;
        justify-content: center;
        cursor: pointer;
        
    }

    .navheader{
        font-size: 4vw;
    }
}

@media screen and (max-width: 450px) {
    .navmenu{
        display: block;
        border-top: 5px solid #46e059;
        margin: 0;
        padding: 0;
        right: -100%;
        background: #31a73f;
        height: 100vh;
        position: absolute;
        transition: 1s;
        width: 100vw;
    }

    .navmenu.active{
        width: 100%;
        right: 0;
        transition: 1s;
        
    }
    
    
    .navmenu ul{
        display: inline-block; 
        margin: 0;
        padding-top: 5%;
        text-align: center;
        font-size: 20px;
    }

    .dropdown ul{
        display:block;
        position: relative;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        align-items: center;
        list-style: none;
        
    }

    .navlink{
        width: 70vw;
        margin-top: 1%;
        padding: 10px;
    }

    

    .bars.div{
        display: flex;
        width: 20%;
        justify-content: center;
        cursor: pointer;
        
    }

    .navheader{
        font-size: 4vw;
    }
}